import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from './../services/navigator.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { SedeService } from '../services/sede/sede.service';
import { SedeDTO } from '../shared/dto/sede/sede';

@Component({
  selector: 'app-mio-profilo',
  templateUrl: './mio-profilo.component.html',
  styleUrls: ['./mio-profilo.component.scss']
})
export class MioProfiloComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  
  collaboratoreId: number;
  collaboratore: CollaboratoreDTO;
  sedi: SedeDTO[];

  constructor(
    private collaboratoreService: CollaboratoreService,
    private sedeServices: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
  ) { 
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        email: new FormControl({ value: '', disabled: true }),
        userName: new FormControl({ value: '', disabled: true }),
        nome: new FormControl('', Validators.required),
        cognome: new FormControl('', Validators.required),
        telefono: new FormControl(''),
        sede: new FormControl(null),
        ruolo: new FormControl(''),
        compleannoPersonale: new FormControl(''),
        checkboxCompleanno: new FormControl(''),
        jeniaCompleanno: new FormControl(''),
        deleteDate: new FormControl({ value: '', disabled: true }),
        deleteUser: new FormControl({ value: '', disabled: true }),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
      });
  }

  ngOnInit() {
    this.collaboratoreId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.collaboratoreId != null && this.collaboratoreId != 0) {
      this.collaboratoreRead();
    }
    
    this.sedeServices.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<SedeDTO>)=> {
        console.log("response : " + res);
          this.sedi = res.content;
      });
  }

  collaboratoreSave() {
    if (this.collaboratore.id != null) {
      this.collaboratoreUpdate();
    }
  }
  
  collaboratoreUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il collaboratore?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.collaboratoreService.update(this.collaboratore).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.collaboratoreRead();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }
  
  collaboratoreRead() {
    this.collaboratoreService.read(this.collaboratoreId).subscribe(
      (res: CollaboratoreDTO) => {
        console.log("response : " + res);
        this.collaboratore = res;
        this.collaboratoreId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.collaboratore.email = this.form.get("email").value;
    this.collaboratore.userName = this.form.get("userName").value;
    this.collaboratore.nome = this.form.get("nome").value;
    this.collaboratore.cognome = this.form.get("cognome").value;
    this.collaboratore.telefono = this.form.get("telefono").value;
    this.collaboratore.sede = this.form.get("sede").value;
    this.collaboratore.ruolo = this.form.get("ruolo").value;
    this.collaboratore.compleannoPersonale = this.form.get("compleannoPersonale").value;
    this.collaboratore.compleannoPersonaleNascosto = this.form.get("checkboxCompleanno").value;
    this.collaboratore.jeniaCompleanno = this.form.get("jeniaCompleanno").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.collaboratore.id);
    this.form.get("email").setValue(this.collaboratore.email);
    this.form.get("userName").setValue(this.collaboratore.userName);
    this.form.get("nome").setValue(this.collaboratore.nome);
    this.form.get("cognome").setValue(this.collaboratore.cognome);
    this.form.get("telefono").setValue(this.collaboratore.telefono);
    this.form.get("sede").setValue(this.collaboratore.sede);
    this.form.get("ruolo").setValue(this.collaboratore.ruolo);
    this.form.get("compleannoPersonale").setValue(this.collaboratore.compleannoPersonale);
    this.form.get("checkboxCompleanno").setValue(this.collaboratore.compleannoPersonaleNascosto);
    this.form.get("jeniaCompleanno").setValue(this.collaboratore.jeniaCompleanno);
    this.form.get("deleteDate").setValue(this.collaboratore.deleteDate);
    this.form.get("deleteUser").setValue(this.collaboratore.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.collaboratore.insertDate));
    this.form.get("insertUser").setValue(this.collaboratore.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.collaboratore.updateDate));
    this.form.get("updateUser").setValue(this.collaboratore.updateUser);
    this.form.get("optLock").setValue(this.collaboratore.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

}
