import { CollaboratoreDTO } from './../domain/collaboratore';
import { PostazioneDTO } from './../postazione/postazione';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';

export class PrenotazionePostazioneDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    postazione: PostazioneDTO;
    dataDa: Date;
    dataA: Date;
    richiedente: CollaboratoreDTO;
    note: string;
    prenotazionePerOspite: boolean;
}