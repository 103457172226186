import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { NumeroReperibilitaDTO } from '../shared/dto/reperibilità/NumeroReperibilita';
import { NumeroService } from '../services/reperibilità/numero.service';
import { TurnoReperibilitaDTO } from '../shared/dto/reperibilità/TurnoReperobilita';
import { TurnoService } from '../services/reperibilità/turno.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-turni',
  templateUrl: './turniDetail.component.html',
  styleUrls: ['./turniDetail.component.scss']
})

export class TurniDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  giorno: selectedValue[];
  turnoId: number;
  turno: TurnoReperibilitaDTO;
  collaboratori: CollaboratoreDTO[];
  numeri: NumeroReperibilitaDTO[];
  
  constructor(
    private numeroService: NumeroService,
    private turnoService: TurnoService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      numeroReperibilita: new FormControl(null, Validators.required),
      giorno: new FormControl('', Validators.required),
      oraDa: new FormControl('', Validators.required),
      oraA: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.giorno = [
        { value: 'MONDAY', viewValue: 'MONDAY' },
        { value: 'TUESDAY', viewValue: 'TUESDAY' },
        { value: 'WEDNESDAY', viewValue: 'WEDNESDAY' },
        { value: 'THURSDAY', viewValue: 'THURSDAY' },
        { value: 'FRIDAY', viewValue: 'FRIDAY' },
        { value: 'SATURDAY', viewValue: 'SATURDAY' },
        { value: 'SUNDAY', viewValue: 'SUNDAY' },
      ];
  }

  ngOnInit() {
    this.turnoId= Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.turnoId != null && this.turnoId != 0) {
      this.turnoRead();
    } else {
      this.turno = new TurnoReperibilitaDTO();
    }
    this.collaboratoreService.collaboratoreList(
        0,
        9999,
        'ASC',
        'nome',
        '').subscribe(
          (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
            console.log("response : " + res);
            let collab: CollaboratoreDTO[] = [];
            for (const coll of res.content) {
              if (coll?.telefonoReperibilita) {
                collab.push(coll);
              }
            }
            this.collaboratori = collab;
          }
        );
        this.numeroService.numeriList(
            0,
            9999,
            'ASC',
            'descrizione',
            '')
            .subscribe((res: ResponseQueryByCriteria<NumeroReperibilitaDTO>) => {
                this.numeri = res.content
            });
  }

  turnoSave() {
    if (this.turno.id == null) {
      this.turnoCreate();
    } else {
      this.turnoUpdate();
    }
  }

  turnoCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.turnoService.create(this.turno).subscribe(
        (res: TurnoReperibilitaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.turno = res;
          this.turnoId = this.turno.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_INVALID_PARAMETER") {
            this.snackBar.open("Attenzione! Parametri per l'aggiunta del turno errati o invalidi.", null, { duration: 4000 });
          } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidReperibilitaException_TURNO_REPERIBILITA_NOT_FOUND") {
            this.snackBar.open("Attenzione! Numero reperibilità non trovato.", null, { duration: 4000 });
          } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Collaboratore per cui inserire il turno non trovato.", null, { duration: 4000 });
          } 
        }
      );
    }
  }

  turnoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il numero?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.turnoService.update(this.turno).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.turnoRead();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_INVALID_PARAMETER") {
                this.snackBar.open("Attenzione! Parametri per l'aggiunta del turno errati o invalidi.", null, { duration: 4000 });
              } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidReperibilitaException_TURNO_REPERIBILITA_NOT_FOUND") {
                this.snackBar.open("Attenzione! Turno reperibilità non trovato.", null, { duration: 4000 });
              } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_NOT_FOUND") {
                this.snackBar.open("Attenzione! Collaboratore per cui inserire il turno non trovato.", null, { duration: 4000 });
              } 
            }
          );
        }
      });
    }
  }

  turnoDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il turno della reperibilità?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.turnoService.delete(this.turno.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/turni-reperibilita"]);
            }
          );
        }
      });
    }
  }

  turnoRead() {
    this.turnoService.read(this.turnoId).subscribe(
      (res: TurnoReperibilitaDTO) => {
        console.log("response : " + res);
        this.turno = res;
        this.turnoId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.turno.collaboratore = this.form.get("collaboratore").value;
    this.turno.numeroReperibilita = this.form.get("numeroReperibilita").value
    this.turno.giorno = this.form.get("giorno").value;
    let oraDa = this.form.get("oraDa").value.split(':');
    this.turno.oraDa = oraDa[0] + ':' + oraDa[1] + ':00';
    let oraA = this.form.get("oraA").value.split(':')
    this.turno.oraA = oraA[0] + ':' + oraA[1] + ':00';
  }
 
  private dtoToForm(): void {
    this.form.get("id").setValue(this.turno.id);
    this.form.get("collaboratore").setValue(this.turno?.collaboratore);
    this.form.get("numeroReperibilita").setValue(this.turno?.numeroReperibilita);
    this.form.get("giorno").setValue(this.turno?.giorno);
    let oraDa = this.turno.oraDa.split(':');
    this.form.get("oraDa").setValue(oraDa[0]+ ':' + oraDa[1]);
    let oraA = this.turno.oraA.split(':');
    this.form.get("oraA").setValue(oraA[0]+ ':' + oraA[1]);
    this.form.get("deleteDate").setValue(this.turno?.deleteDate);
    this.form.get("deleteUser").setValue(this.turno?.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.turno?.insertDate));
    this.form.get("insertUser").setValue(this.turno?.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.turno?.updateDate));
    this.form.get("updateUser").setValue(this.turno?.updateUser);
    this.form.get("optLock").setValue(this.turno?.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

export interface selectedValue {
    value: string;
    viewValue: string;
  }