import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { PrenotazionePostazioneDTO } from './../../shared/dto/prenotazionepostazione/prenotazionepostazione';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs/internal/Observable';
import { SedeDTO } from 'src/app/shared/dto/sede/sede';
import { PostazioneDTO } from 'src/app/shared/dto/postazione/postazione';

@Injectable({
  providedIn: 'root'
})
export class PrenotazionePostazioneService extends GenericService<PrenotazionePostazioneDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/prenotazionepostazione';
  }

  public prenotazionePostazioneList(pageNumber: number, pageSize: number, direction: string, sortField: string, richiedente?: CollaboratoreDTO, sede?: SedeDTO, dataDa?: Date , dataA?: Date ): Observable<ResponseQueryByCriteria<PrenotazionePostazioneDTO>> {
    let params = {};
    if (pageNumber != null){
      params["pageNumber"] = String(pageNumber);
    }
    if (pageSize != null){
      params["pageSize"] = String(pageSize);
    }
    if (direction != null){
      params["direction"] = direction;
    }
    if (sortField != null){
      params["sortField"] = sortField;
    }
    if (richiedente != null){ 
      params["richiedente"] = richiedente.id;
    }
    if (sede != null){
      params["sede"] = sede.id;
    }
    if (dataDa != null){ 
      params["dataDa"] = dataDa.toISOString();
    }
    if (dataA != null){
      params["dataA"] = dataA.toISOString();
    }
    return this.http.get<ResponseQueryByCriteria<PrenotazionePostazioneDTO>>(`${this.url}/prenotazionepostazioneListByList`, {
      params: params
    });
  }

  public postazioniDisponibiliList(pageNumber: number, pageSize: number, direction: string, sortField: string, dataDa: Date , dataA: Date, idExclude?: number,  sede?: SedeDTO, filter?: string ): Observable<ResponseQueryByCriteria<PostazioneDTO>> {
    let params = {};
    if (pageNumber != null) {
      params["pageNumber"] = String(pageNumber);
    }
    if (pageSize != null)   {
      params["pageSize"] = String(pageSize);
    }
    if (direction != null)  {
      params["direction"] = direction;
    }
    if (sortField != null)  {
      params["sortField"] = sortField;
    }
    console.log(dataDa+" Data da ");
    console.log( new Date(dataDa).toISOString()+" new date ");
    if (dataDa != null)     {
      params["dataDa"] = new Date(dataDa).toISOString();
    }
    if (dataA != null)      {
      params["dataA"] = new Date(dataA).toISOString();
    }
    if (sede != null)       {
      params["idExclude"] = idExclude;
    }
    if (sede != null)       {
      params["sede"] = sede.id;
    }
    if (filter != null)     {
      params["filter"] = filter;
    }
    return this.http.get<ResponseQueryByCriteria<PostazioneDTO>>(`${this.url}/postazioniDisponibiliList`, {
      params: params
    });
  }

  public situazionePostazioniList(pageNumber: number, pageSize: number, direction: string, sortField: string, dataDa: Date , dataA: Date): Observable<PrenotazionePostazioneDTO[]> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    console.log(dataDa+" Data da ");
    console.log( new Date(dataDa).toISOString()+" new date ");
    if (dataDa != null) params["dataDa"] = new Date(dataDa).toISOString();
    if (dataA != null) params["dataA"] = new Date(dataA).toISOString();
    return this.http.get<PrenotazionePostazioneDTO[]>(`${this.url}/situazionePostazioniList`, {
      params: params
    });
  }

  public ottieniPrenotazioneGiornalieraByCollaboratore(collaboratore: CollaboratoreDTO): Observable<PrenotazionePostazioneDTO> {
    let params = {};
    if (collaboratore != null){ 
      params["idRichiedente"] = collaboratore.id;
    }
      let d:Date = new Date()
      d.setHours(0, 0, 0, 0);
      params["data"] = d.toISOString();

    return this.http.get<PrenotazionePostazioneDTO>(`${this.url}/prenotazionepostazioneByGiornoAndRichiedente`, {
      params: params
    });
  }


}
