import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { AssenzaService } from '../services/assenza/assenza.service';
import { ChiusuraService } from '../services/assenza/chiusura.service';
import { CompleanniService } from '../services/compleanni/compleanni.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ComunicazioneService } from '../services/comunicazioni/comunicazione.service';
import { NascondiComunicazioneService } from '../services/comunicazioni/nascondiComunicazione';
import { NavigatorService } from '../services/navigator.service';
import { PreferitiService } from '../services/preferiti/preferiti.service';
import { PrenotazionePostazioneService } from '../services/prenotazionepostazione/prenotazionepostazione.service';
import { ServizioService } from '../services/servizio/servizio.service';
import { AssenzaDTO } from '../shared/dto/assenza/assenza';
import { ChiusuraDTO } from '../shared/dto/assenza/chiusura';
import { ComunicazioneDTO } from '../shared/dto/comunicazioni/comunicazione';
import { ComunicazioneNascostaDTO } from '../shared/dto/comunicazioni/comunicazioneNascosta';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { TenantDTO } from '../shared/dto/domain/tenant';
import { PostazioneDTO } from '../shared/dto/postazione/postazione';
import { PreferitiDTO } from '../shared/dto/preferiti/preferiti';
import { ServizioDTO } from '../shared/dto/preferiti/servizio';
import { PrenotazionePostazioneDTO } from '../shared/dto/prenotazionepostazione/prenotazionepostazione';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { finalize, take } from 'rxjs/operators';
import { MenuService } from "../services/menu/menu.service";
import { MenuDTO } from "../shared/dto/menu/menu";
import { MenuStore } from "../services/menu/menu.store";
import { Observable } from "rxjs";
import { FunzionalitaDTO } from '../shared/dto/menu/funzionalita';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

    tenant: TenantDTO;

    collaboratore: CollaboratoreDTO;

    comunicazione: ComunicazioneDTO;

    comunicazioniPersonali: ComunicazioneDTO[];

    comunicazionePersonaliNascoste: ComunicazioneDTO[];

    comunicazioniPersonaliNuoveBadgeNumber: number;

    comunicazioniAziendali: ComunicazioneDTO[];

    comunicazioniAziendaliNascoste: ComunicazioneDTO[];

    comunicazioniAziendaliNuoveBadgeNumber: number;

    comunicazioneNascosta: ComunicazioneNascostaDTO;

    preferiti: PreferitiDTO[];

    lengthPreferiti: number;

    servizi: ServizioDTO[];

    myMap = {
        PRESENZE_PRESENZA: false,
        PRESENZE_GESTIONE_PRESENZA: false,
        PRESENZE_MALATTIA: false,
        PRESENZE_LISTA: false,
        PRESENZE_GESTIONE_ASSENZE: false,
        PRESENZE_CONFIGURA_ATTIVITA: false,
        PRESENZE_RAPPORTINO_FINALE: false,
        PRESENZE_DOVE_SEI: false,
        PRESENZE_CHIUSURA_AZIENDALE: false,
        PRESENZE_PRENOTA_POSTAZIONE: false,
        PRESENZE_CALENDARIO: false,
        PRESENZE_LISTA_ATTESA_POSTAZIONE: false,
        PAUSA_CAFFE: false,
        ORDINIAMO: false,
        ORDINA: false,
        PAUSA_COSA_SI_MANGIA_OGGI: false,
        PAUSA_TORNEO: false,
        TRASFERTE_PRENOTAZIONE_TRASFERTE: false,
        TRASFERTE_RIMBORSI: false,
        AMMINISTRAZIONE_DATI_ANAGRAFICI: false,
        AMMINISTRAZIONE_COMUNICAZIONE: false,
        AMMINISTRAZIONE_NUMERO_REPERIBILITA: false,
        AMMINISTRAZIONE_TURNO_REPERIBILITA: false,
        AMMINISTRAZIONE_ACQUISTI: false,
        AMMINISTRAZIONE_CONFIGURA_LOCATION: false,
        AMMINISTRAZIONE_CONFIGURA_CHIAVI: false,
        AMMINISTRAZIONE_PRENDI_LASCIA_CHIAVI: false,
        AMMINISTRAZIONE_STORIA_CHIAVI: false,
        AMMINISTRAZIONE_GESTIONE_FERIE: false,
        AMMINISTRAZIONE_CONFIGURA_SEDE: false,
        AMMINISTRAZIONE_CONFIGURA_SALETTA: false,
        AMMINISTRAZIONE_PRENOTA_UNA_SALETTA: false,
        AMMINISTRAZIONE_COLLABORATORE: false,
        AMMINISTRAZIONE_GESTIONE_CATALOGO: false,
        AMMINISTRAZIONE_GESTIONE_DISPOSITIVI: false,
        AMMINISTRAZIONE_CONFIGURA_POSTAZIONI: false,
        AMMINISTRAZIONE_CONFIGURA_UFFFICIO: false,
        AMMINISTRAZIONE_CONFIGURA_RISORSA: false,
        AMMINISTRAZIONE_CONFIGURA_CLIENTE: false,
        AMMINISTRAZIONE_CONFIGURA_TURNI_AM: false,
        AMMINISTRAZIONE_COLLABORATORI_AM: false,
        PRESENZE_TURNI_AM: false,
        AMMINISTRAZIONE_CORSIUDEMY: false,
        AMMINISTRAZIONE_IMIEICORSI: false,
        AMMINISTRAZIONE_CALENDARIO: false,
        AMMINISTRAZIONE_COLLOQUI: false,
        AMMINISTRAZIONE_BUSTE_PAGA: false,
        CONFIGURAZIONE_TENANT: false,
        CONFIGURAZIONE_TEMPLATE: false,
        ALTRO_CAMBIO_PASSWORD: false,
        ALTRO_HELP: false,
        ALTRO_RISORSE_AZIENDALI: false,
        ALTRO_ABOUT: false,
        ALTRO_MIOPROFILO: false,
        ALTRO_LOGOUT: false
    };


    selectedIndex = 0;

    chiusure: ChiusuraDTO[];

    assenze: AssenzaDTO[];

    menu$: Observable<MenuDTO[]>;

    prenotazionePostazione: PrenotazionePostazioneDTO = null;
    postazionePrenotata: PostazioneDTO = null;
    jeniaCompleanni: CollaboratoreDTO[] = [];
    compleanniPersonali: CollaboratoreDTO[] = [];
    collaboratoreLocalJC: CollaboratoreDTO
    collaboratoreLocalC: CollaboratoreDTO


    today = new Date();

    untilNextWeek = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 7,
    );

    parameters = {
        dataSource: [],
        showList: false,
        sortField: "insertDate",
        sortDirection: "DESC",
        stato: 'APPROVATO',
        tipoRichiesta: 'FERIE',
        pageNumber: 0,
        pageSize: 5,
        length: 0
    }

    constructor(private navigatorService: NavigatorService,
        private comunicazioneService: ComunicazioneService,
        private prenotazionePostazioneService: PrenotazionePostazioneService,
        private compleanniService: CompleanniService,
        private nascondiComunicazioneService: NascondiComunicazioneService,
        private componentCacheService: ComponentCacheService,
        private chiusuraService: ChiusuraService,
        private assenzaService: AssenzaService,
        private preferitiService: PreferitiService,
        private menuService: MenuStore,
        private servizioService: ServizioService,
        private router: Router) {
    }

    ngOnInit() {
        this.selectedIndex = 0;
        this.navigatorService.tenant.pipe(take(1)).subscribe(
            (res: TenantDTO) => {
                this.tenant = res;
                this.listByComunicazioneAziendale();
                this.chiusuraUntilNextWeek();
            }
        );
        this.navigatorService.collaboratore.pipe(take(1)).subscribe(
            (res: CollaboratoreDTO) => {
                this.collaboratore = res;
                this.listByComunicazionePersonale();
                this.listByComunicazionePersonaleNascosta();
                this.listByComunicazioneAziendaleNascosta();
                this.assenzaUntilNextWeek();
                this.listPreferiti();
                this.listServizi();
                this.ottieniPrenotazioneOggi();
                this.ottieniJeniaCompleanniOggi();
                this.ottieniCompleanniPersonaliOggi();
                this.loadListMenu();
            }
        );
    }

    listByComunicazionePersonale() {
        this.comunicazioneService.listByComunicazionePersonale(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            this.collaboratore).pipe(take(1)).subscribe(
                (res: ResponseQueryByCriteria<ComunicazioneDTO>) => {
                    this.comunicazioniPersonali = res.content;
                    this.parameters.pageNumber = res.pageNumber;
                    this.parameters.length = res.totalElements;
                    this.parameters.showList = true;
                }
            )
    }

    listByComunicazionePersonaleNascosta() {
        this.comunicazioneService.listByComunicazionePersonaleNascosta(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            this.collaboratore).pipe(take(1)).subscribe(
                (res: ResponseQueryByCriteria<ComunicazioneDTO>) => {
                    this.comunicazionePersonaliNascoste = res.content;
                    this.comunicazioniPersonaliNuoveBadgeNumber = 0;
                    for (let { } of res.content) {
                        this.comunicazioniPersonaliNuoveBadgeNumber += 1;
                    }
                    this.parameters.pageNumber = res.pageNumber;
                    this.parameters.length = res.totalElements;
                    this.parameters.showList = true;
                }
            )
    }

    listByComunicazioneAziendale() {
        this.comunicazioneService.listByComunicazioneAziendale(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField).pipe(take(1)).subscribe(
                (res: ResponseQueryByCriteria<ComunicazioneDTO>) => {
                    this.comunicazioniAziendali = res.content;
                    this.parameters.pageNumber = res.pageNumber;
                    this.parameters.length = res.totalElements;
                    this.parameters.showList = true;
                }
            )
    }

    listByComunicazioneAziendaleNascosta() {
        this.comunicazioneService.listByComunicazioneAziendaleNascosta(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            this.collaboratore).pipe(take(1)).subscribe(
                (res: ResponseQueryByCriteria<ComunicazioneDTO>) => {
                    this.comunicazioniAziendaliNascoste = res.content;
                    this.comunicazioniAziendaliNuoveBadgeNumber = 0;
                    for (let { } of res.content) {
                        this.comunicazioniAziendaliNuoveBadgeNumber += 1;
                    }
                    this.parameters.pageNumber = res.pageNumber;
                    this.parameters.length = res.totalElements;
                    this.parameters.showList = true;
                }
            )
    }

    nascondiComunicazione(comunicazione: ComunicazioneDTO) {
        if (this.comunicazioneNascosta == null) {
            this.comunicazioneNascosta = new ComunicazioneNascostaDTO();
        }
        this.comunicazioneNascosta.comunicazione = comunicazione;
        this.comunicazioneNascosta.collaboratore = this.collaboratore;
        this.nascondiComunicazioneService.create(this.comunicazioneNascosta).pipe(take(1)).subscribe(() => {
            this.listByComunicazionePersonaleNascosta();
            this.listByComunicazioneAziendaleNascosta();
        });
    }

    chiusuraUntilNextWeek() {
        this.chiusuraService.chiusuraListByList(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            this.today,
            this.untilNextWeek).pipe(take(1)).subscribe(
                (res: ResponseQueryByCriteria<ChiusuraDTO>) => {
                    this.chiusure = res.content;
                    this.parameters.pageNumber = res.pageNumber;
                    this.parameters.length = res.totalElements;
                    this.parameters.showList = true;
                }
            )
    }

    assenzaUntilNextWeek() {
        this.assenzaService.assenzaListByList(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            this.collaboratore,
            this.parameters.stato,
            this.today,
            this.untilNextWeek,
            this.parameters.tipoRichiesta).pipe(take(1)).subscribe(
                (res: ResponseQueryByCriteria<AssenzaDTO>) => {
                    this.assenze = res.content;
                    this.parameters.pageNumber = res.pageNumber;
                    this.parameters.length = res.totalElements;
                    this.parameters.showList = true;
                }
            )
    }

    ottieniPrenotazioneOggi() {
        this.prenotazionePostazioneService.ottieniPrenotazioneGiornalieraByCollaboratore(
            this.collaboratore
        ).pipe(take(1)).subscribe(
            (res: PrenotazionePostazioneDTO) => {
                this.prenotazionePostazione = res;
                if (this.prenotazionePostazione) {
                    this.postazionePrenotata = this.prenotazionePostazione.postazione;
                }
            })
    }

    ottieniJeniaCompleanniOggi() {
        this.compleanniService.ottieniJeniaCompleanni(
        ).pipe(take(1)).subscribe(
            (res: CollaboratoreDTO[]) => {
                for (let j = 0; j < res.length; j++) {
                    if (res[j].id != this.collaboratore.id) this.jeniaCompleanni.push(res[j]);
                    else if (res[j].id == this.collaboratore.id) this.collaboratoreLocalJC = res[j];
                }
            })
    }

    ottieniCompleanniPersonaliOggi() {
        this.compleanniService.ottieniCompleanniPersonali(
        ).pipe(take(1)).subscribe(
            (res: CollaboratoreDTO[]) => {
                for (let j = 0; j < res.length; j++) {
                    if (res[j].id != this.collaboratore.id) this.compleanniPersonali.push(res[j]);
                    else if (res[j].id == this.collaboratore.id) this.collaboratoreLocalC = res[j];
                }
            })
    }


    loadListMenu() {
        this.menuService.loadAllMenu();
        this.menu$ = this.menuService.getListMenuObservable();
    }
    listPreferiti() {

        this.preferitiService.preferitiList(
            this.collaboratore.id
        ).pipe(take(1),
            finalize(() => { this.myMap = Object.assign({}, this.myMap); })//per triggerare onChanges
        ).subscribe(

            (res: PreferitiDTO[]) => {
                let oldLength = this.lengthPreferiti;
                this.preferiti = res;
                this.preferiti.forEach(preferito => {
                    this.myMap[preferito.servizio.codServizio] = true;
                })
                this.lengthPreferiti = this.preferiti.length;
                if (oldLength == null) {
                    this.selectedIndex = 0;
                } else if (this.lengthPreferiti == 0 && oldLength == 1) {
                    this.selectedIndex--;
                } else if (this.lengthPreferiti == 1 && oldLength == 0) {
                    this.selectedIndex++;
                }
            }

        )
    }

    showPreferiti() {
        return this.lengthPreferiti != 0;
    }

    listServizi() {
        this.servizioService.serviziList().pipe(take(1)).subscribe(
            (res: ServizioDTO[]) => {
                this.servizi = res;
            }
        )
    }

    getPreferito(value: string) {
        return this.myMap[value];
    }

    modificaPreferito(servizioCod: string) {
        if (this.myMap[servizioCod] == false) {
            let preferito = new PreferitiDTO();
            let servizioEstratto = new ServizioDTO();
            this.servizi.forEach(servizio => {
                if (servizio.codServizio == servizioCod) {
                    servizioEstratto = servizio;
                }
            })
            preferito.servizio = servizioEstratto;
            preferito.collaboratore = this.collaboratore;
            this.preferitiService.create(preferito).subscribe(() => {
                this.listPreferiti();
                this.myMap = Object.assign({}, this.myMap);
            })
        } else if (this.myMap[servizioCod] == true) {
            let preferitoEstratto = new PreferitiDTO();
            this.preferiti.forEach(preferito => {
                if (preferito.servizio.codServizio == servizioCod) {
                    preferitoEstratto = preferito;
                }
            })
            this.preferitiService.delete(preferitoEstratto.id).subscribe(() => {
                this.myMap[preferitoEstratto.servizio.codServizio] = false;
                this.listPreferiti();
                this.myMap = Object.assign({}, this.myMap);
            })
        }
    }

    setLength(lunghezza: number) {
        this.lengthPreferiti = lunghezza;
    }


    isSameDate(d1: Date, d2: Date): boolean {
        if (d1 == null || d2 == null) {
            return false;
        } else {
            return dayjs(d1).isSame(d2, 'day');
        }
    }

    isLoggedIn(): boolean {
        return this.navigatorService.isLoggedIn();
    }

    isJappTenantAdmin(): boolean {
        return this.navigatorService.isJappTenantAdmin();
    }

    isJappAdmin(): boolean {
        return this.navigatorService.isJappAdmin();
    }

    logout() {
        this.navigatorService.logout();
    }

    isActive(value: string): boolean {
        return this.navigatorService.isActive(value);
    }

    ngOnDestroy(): void {
        this.componentCacheService.set(HomeComponent.name, this.parameters);
    }

    navigateTo(funzionalita: FunzionalitaDTO) {
        if (funzionalita.linkRisorsa.startsWith("http")) {
            window.open(funzionalita.linkRisorsa, "_blank");
        } else {
            this.router.navigateByUrl(funzionalita.linkRisorsa);
        }
    }
}
