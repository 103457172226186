<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione turni reperibilità AM</h2>
        <div fxLayout="column" class="responsive-mat-form">
          <mat-form-field>
            <mat-label>Numero reperibilità</mat-label>
            <mat-select [(ngModel)]="parameters.filter">
                <mat-option *ngFor="let numero of numeri" [value]="numero">
                   {{ numero.descrizione }} - {{ numero.mainNumber }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/turni-reperibilita/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let turno"> {{turno.id}} </td>
            </ng-container>
            
            <!-- giorno Column -->
            <ng-container matColumnDef="giorno">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Giorno </th>
              <td mat-cell *matCellDef="let turno"> {{turno.giorno}} </td>
            </ng-container>

            <!-- oraDa Column -->
            <ng-container matColumnDef="oraDa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > OraDa </th>
                <td mat-cell *matCellDef="let turno"> {{turno.oraDa}} </td>
            </ng-container>

            <!-- oraA Column -->
            <ng-container matColumnDef="oraA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > OraA </th>
                <td mat-cell *matCellDef="let turno"> {{turno.oraA}} </td>
            </ng-container>

            <!-- collaboratore Column -->
            <ng-container matColumnDef="collaboratore">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Collaboratore </th>
                <td mat-cell *matCellDef="let turno"> {{turno.collaboratore.nome}} {{turno.collaboratore.cognome}} </td>
            </ng-container>

            <!-- collaboratore.telefono Column -->
            <ng-container matColumnDef="collaboratore.telefonoReperibilita">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Cellulare collaboratore </th>
                <td mat-cell *matCellDef="let turno"> {{turno.collaboratore.telefonoReperibilita}} </td>
            </ng-container>
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef [hidden]="!isJappTenantAdmin()"></th>
                <td mat-cell *matCellDef="let turno" [hidden]="!isJappTenantAdmin()">
                  <button mat-mini-fab color="primary" [routerLink]="['/turni-reperibilita/detail/', turno.id]" [hidden]="!isJappTenantAdmin()"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
      </div>
    </mat-card-content>
  </mat-card>