import { Component, OnInit, HostListener} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import { NavigatorService } from './services/navigator.service';
import { TenantDTO } from './shared/dto/domain/tenant';
import { ResponseFail } from './shared/dto/responseFail';
import { CollaboratoreDTO } from './shared/dto/domain/collaboratore';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { UrlService } from './commons/calendar/prevUrl.service';
import { filter, map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ServizioService } from './services/servizio/servizio.service';
import { Observable } from 'rxjs';
import {MenuService} from "./services/menu/menu.service";
import {MenuDTO} from "./shared/dto/menu/menu";
import {MenuStore} from "./services/menu/menu.store";
import { FunzionalitaDTO } from './shared/dto/menu/funzionalita';

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sinekarta4-console';
  previousUrl: string = null;
  currentUrl: string = null;
  tenant : TenantDTO;
  collaboratore : CollaboratoreDTO;
  screenWidth: number;
  id:number;
  menuList$: Observable<MenuDTO[]> = new Observable<MenuDTO[]>();
  filteredServizio: Observable<funzionalita[]>;
  filter: FormControl;

  httpError : HttpErrorResponse;

  constructor(public mediaObserver: MediaObserver, 
              public navigatorService : NavigatorService,
              public snackBar : MatSnackBar,
              private router: Router,
              private menu: MenuStore, //sostituire con store
              private urlService: UrlService) {
    this.filter = new FormControl();         
    navigatorService.tenantInfo();
    navigatorService.tenant.subscribe(
      (res: TenantDTO) => {
        this.tenant = res;
      }
    );
    navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        if(!this.isJappTenantAdmin()){
          this.servizi = this.servizi.filter((value : funzionalita)=> value.isAdmin===false);
        }
        this.id = this.collaboratore.id;
        this.servizi.forEach((s:funzionalita)=>{
          if(s.nome==="Mio Profilo"){
            s.link+=this.id;
          }
        });
      }
    );
    this.filteredServizio = this.filter.valueChanges.pipe(
      startWith(''),
      map(servizio => servizio ? this.filtroServizio(servizio) : this.servizi.slice()),
    );
    navigatorService.httpError.subscribe(
      (err : HttpErrorResponse) => { 
        let message : string;
        if (err.error!= null && err.error.reasonBundleKey!=undefined) {
          let fail : ResponseFail = err.error;
          message = fail.reasonBundleKey + ' - ' + fail.reason, 'Error';
        } else {
          if(err.status == 403){
            message = "L'utente non ha i permessi necessari per svolgere questa operazione.";
          } else {
            message = 'Generic error contacting server! status : ' + err.status + ', message : ' + err.message;
          }
        }
        this.snackBar.open(message, 'Error', {
          duration: 5000
        });
      }
    );
  }

  menuToExclude :string[] = [
      "Comunicazioni", "Bacheca"
  ]
  //isAdmin = false servizio visibile dagli utenti
  //isAdmin = true servizio visibile solo dall'amministratore
  servizi: funzionalita[] =[
    {nome: "Gestione Presenze", link: "gestione-presenza", isAdmin: false},
    {nome: "Gestione Assenze", link: "registra-assenza", isAdmin: false},
    {nome: "Malattia", link: "registra-malattia", isAdmin: false},
    {nome: "Configura Attività", link: "attivita", isAdmin: false},
    {nome: "Rapportino Mensile", link: "rapportino-finale", isAdmin: false},
    {nome: "Dove sei", link: "dove-sei", isAdmin: false},
    {nome: "Chiusura Aziendale", link: "chiusura", isAdmin: false},
    {nome: "Prenota Postazione", link: "prenotazionepostazione", isAdmin: false},
    {nome: "Lista Attesa", link: "listaattesaprenotazionepostazione", isAdmin: false},
    {nome: "Calendario", link: "calendario", isAdmin: false},
    {nome: "Turni AM", link: "turno-am", isAdmin: false},
    {nome: "Lista presenze", link: "visualizza-presenze", isAdmin: false},
    {nome: "Caffè", link: "gestione-credito", isAdmin: false},
    {nome: "Ordiniamo", link: "gestione-comanda", isAdmin: false},
    {nome: "Ordina", link: "gestione-ordine", isAdmin: false},
    {nome: "Cosa si mangia oggi", link: "proposta", isAdmin: false},
    {nome: "Torneo", link: "torneo", isAdmin: false},
    {nome: "Prenotazione Viaggi", link: "gestione-prenotazione", isAdmin: false},
    {nome: "Rimborsi", link: "rimborso-pie-di-lista", isAdmin: false},
    {nome: "Dati Anagrafici", link: "dati-anagrafici", isAdmin: false},
    {nome: "Comunicazioni", link: "comunicazione", isAdmin: false},
    {nome: "Colloqui", link: "colloquio", isAdmin: true},
    {nome: "Acquisti", link: "richiesta-acquisto", isAdmin: false},
    {nome: "Configura Location", link: "location", isAdmin: true},
    {nome: "Configura Chiavi", link: "chiavi", isAdmin: true},
    {nome: "Storia chiavi", link: "storia-chiavi", isAdmin: false},
    {nome: "Gestione chiavi", link: "prendiChiavi", isAdmin: false},
    {nome: "Configura Sede", link: "sede", isAdmin: true},
    {nome: "Configura Sala", link: "saletta", isAdmin: true},
    {nome: "Prenota una sala", link: "gestione-prenotazione-saletta", isAdmin: false},
    {nome: "Collaboratore", link: "collaboratore", isAdmin: true},
    {nome: "Gestione Catalogo", link: "gestione-catalogo", isAdmin: true},
    {nome: "Configura Postazioni", link: "postazione", isAdmin: true},
    {nome: "Configura Ufficio", link: "ufficio", isAdmin: true},
    {nome: "Configura Risorsa", link: "risorsa-aziendale", isAdmin: true},
    {nome: "Configura Cliente", link: "cliente", isAdmin: true},
    {nome: "Configura Turni AM", link: "tipo-turno-am", isAdmin: true},
    {nome: "Collaboratori AM", link: "collaboratore-am", isAdmin: true},
    {nome: "Corsi Udemy", link: "corsoudemy", isAdmin: true},
    {nome: "Formazione", link: "frequentacorso", isAdmin: false},
    {nome: "Calendario Amministrazione", link: "calendario-amministrazione", isAdmin: true},
    {nome: "Tenant", link: "tenant", isAdmin: true},
    {nome: "Template", link: "gestione-template", isAdmin: true},
    {nome: "Cambio Password", link: "cambio-password", isAdmin: false},
    {nome: "Help", link: "help", isAdmin: false},
    {nome: "Risorse Aziendale", link: "risorse-aziendali", isAdmin: false},
    {nome: "Mio Profilo", link: "mio-profilo/", isAdmin: false},
    {nome: "About", link: "about", isAdmin: false},
    {nome: "Gestione ferie", link: "ferie", isAdmin: false}
  ];

  private filtroServizio(value: string): funzionalita[] {
    const filterValue = value.toLowerCase();
    return this.servizi.filter(servizio => servizio.nome.toLowerCase().includes(filterValue));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = screen.width < window.innerWidth ? screen.width : window.innerWidth;
    this.navigatorService.setScreenWidth(this.screenWidth);
  }

  logout() {
    this.navigatorService.logout();
  }

  isJappAdmin() : boolean {
    return this.navigatorService.isJappAdmin();
  }

  isJappTenantAdmin() : boolean {
    return this.navigatorService.isJappTenantAdmin();
  }

  hasRole(role: string) : boolean {
    return this.navigatorService.hasRole(role);
  }

  isLoggedIn() : boolean {
    return this.navigatorService.isLoggedIn();
  }

  ngOnInit(){
    this.onResize(null);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });

    /*
    *   mettiamo solo i menu che non sono contenuti nel **menuToExclude**
     */

      this.menuList$ = this.menu.getListMenuObservable().pipe(
          map(menu=> menu.filter(menuItem => !this.menuToExclude.includes(menuItem.nome)))
      );

  }


  redirect(fun){
    let a = this.servizi.find(servizio => servizio.nome.includes(fun));
    this.router.navigate([a.link]);
  }

  navigateTo(funzionalita: FunzionalitaDTO) {
    if (funzionalita.linkRisorsa.startsWith("http")) {
      window.open(funzionalita.linkRisorsa, "_blank");
    } else {
      this.router.navigateByUrl(funzionalita.linkRisorsa);
    }
  }

}

export class funzionalita{

  nome: string;
  link: string;
  isAdmin: boolean;
  
  constructor(name: string, link: string, isAdmin: boolean){}
}
