import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TurnoService } from '../services/reperibilità/turno.service';
import { TurnoReperibilitaDTO } from '../shared/dto/reperibilità/TurnoReperobilita';
import { NumeroService } from '../services/reperibilità/numero.service';
import { NumeroReperibilitaDTO } from '../shared/dto/reperibilità/NumeroReperibilita';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Injectable({
    providedIn: 'root'
})

@Component({
  selector: 'app-turni',
  templateUrl: './turni.component.html',
  styleUrls: ['./turni.component.scss']
})

export class TurniComponent extends GenericListComponent implements OnInit, OnDestroy {
  numeri: NumeroReperibilitaDTO[];
  giorno: selectedValue[];
  defaultSortField = 'id';
  defaultSortDirection = 'asc';
  constructor(
    private numeroService: NumeroService,
    private turnoService: TurnoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'giorno', 'oraDa', 'oraA', 'collaboratore', 'collaboratore.telefonoReperibilita', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sortField: 'id',
      sortDirection: 'DESC',
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
      let secondSortField : string; 
      if (this.parameters.sortField == 'giorno') {
        secondSortField = 'oraDa';
      }
      this.turnoService.turniList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
        (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
        secondSortField,
        this.parameters.filter).subscribe(
          (res: ResponseQueryByCriteria<TurnoReperibilitaDTO>) => {
            console.log("response : " + res);
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
            this.parameters.showList = true;
          }, (err: HttpErrorResponse) => {
            if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_REQUIRED_FIELD_MISSING") {
              this.snackBar.open("Attenzione! Errore nel download dei turni: nessun numero della reperibilità selezionato.", null, { duration: 4000 });
            } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidReperibilitaException_TURNO_REPERIBILITA_NOT_FOUND") {
              this.snackBar.open("Attenzione! Turni per questo numero reperibilità non trovati.", null, { duration: 4000 });
            }
          }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.numeroService.numeriList(
    0,
    9999,
    'ASC',
    'descrizione',
    '')
    .subscribe((res: ResponseQueryByCriteria<NumeroReperibilitaDTO>) => {
        this.numeri = res.content
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

export interface selectedValue {
  value: string;
  viewValue: string;
}
